import React, { useEffect, useState } from 'react';
import _, { isArray, isEmpty } from 'lodash';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { MoreButton, TableFilter } from '../../../component/Button';
import {
  DndContext,
  closestCenter,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
  LayoutMeasuringStrategy,
} from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import SortableItem, { Item } from './SortableItem';
import { labSettingFilters } from '../constant';
import CaseItemList from './CaseItemList';
import { scrollbar } from '@/constants/style';
import DrawerComponent from './Drawer';
import CaseCategoryForm from './CaseCategoryForm';
import { Button } from '../../../component/Button';
import {
  getCaseLi,
  createCaseCategory,
  editCaseCategory,
  sortCaseCategory,
  deleteCaseCategory,
  createCaseItem,
  editCaseItem,
  deleteCaseItem,
  archivedCaseCategory,
} from '../utils/handleCaseLiData';
import Empty from '../../../component/Empty';
import { useForm } from 'react-hook-form';
import {
  resetCategoryForm,
  editingCategoryForm,
  editingItemForm,
  selectCategoryItem,
  resetDeleteForm,
  getDeleteTarget,
  resetItemForm,
  selectCategory,
  selectItem,
} from '../slice/caseLiSlice';
import { messageSuccess, messageError, messageWarning } from '@/component/Message';
import { sortByASC, sortByDESC } from '../utils/sortBySelect';
import DeleteModal from './DeleteModal';
import { sortBySelect } from '../utils/sortBySelect';
import { filterBySearch } from '../utils/filterBySearch';
import { categoryCaseListItem } from '../utils/categoryCaseListItem';
import CustomizeModal from '../../../component/Modal';
import CaseItemCountingForm from './CaseItemCountingForm';
import {
  resetUsedList,
  setClientError,
  setDuplicateName,
  setHasChildrenModalVisible,
  setUsedCaseModalVisible,
} from '../../HomePage/slice/errorCenterSlice';
import { EllipsisText } from '../../../component/Display';
import Validate from '../../../models/validate';
import Spin from '../../../component/Spin';
import { ReactComponent as InfoIcon } from '../../../images/1-2-icon-his-icons-info.svg';

//#region
const CaseLiContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 1fr) 4fr;
  margin: 0 28px 20px 28px;

  .caseContent__left {
    .caseContent__stickyArea {
      position: sticky;
      top: 74px;
      border: 1px solid var(--light-100-dark);
      height: fit-content;
      border-radius: 8px 0 0 8px;
      border-right: none;
    }
    .caseContent__filter {
      padding: 16px 24px;
      border-bottom: 1px solid var(--border-bottom);
      margin: 0;
      display: flex;
      justify-content: space-between;
      & > div > span {
        font-weight: bold;
      }
    }
    .caseContent__dragList {
      overflow-y: scroll;
      height: calc((100vh - 270px) - 1.75em);
      border-radius: 0 0 0 8px;
      ${scrollbar};
      &::-webkit-scrollbar {
        width: 0;
      }
      .dragList__archivedList--isEmpty {
        height: 100%;
      }
      .dragList__archivedItem {
        padding: 11px 0 11px 60px;
        display: flex;
        border-bottom: 1px solid var(--border-bottom);
        &.selected {
          background-color: var(--color-fill-1-primary-700);
          color: var(--default-500-light);
          &:hover {
            background-color: var(--color-fill-1-primary-700);
          }
          svg > g > g {
            fill: var(--default-500-light);
          }
          .dragList__archived,
          .dragList__archivedAlert {
            color: var(--default-500-light);
          }
        }
        .dragList__archived {
          color: var(--default-500-hint);
          font-style: italic;
          line-height: 15px;
        }
        .dragList__archivedAlert {
          font-size: 12px;
        }
        &:hover {
          background-color: var(--basic-fill-02-200);
          .caseDragList-more {
            visibility: visible;
            opacity: 1;
          }
        }
        .caseDragList-more {
          visibility: hidden;
          opacity: 0;
          transition: opacity 0.1s ease;
          margin-left: 20px;
          &:focus {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }
  .caseContent__right {
    & > div {
      padding: 0;
      height: 100%;
    }
    .caseContent__stickyArea {
      position: sticky;
      top: 74px;
      border: 1px solid var(--light-100-dark);
      border-radius: 0 8px 0 0;
      border-bottom: none;
      z-index: 3;
    }
    .caseContent__header {
      padding: 1.25em 1.25em 5px;
      margin-bottom: 0;
      background-color: var(--default-500-light);
    }
    .caseContent__filter {
      padding: 16px 24px;
      border-bottom: 1px solid var(--border-bottom);
      margin: 0;
      background-color: var(--default-500-light);
      border-radius: 0 8px 0 0;
      & > span {
        font-weight: bold;
      }
    }
    .caseContent__caseList {
      border: 1px solid var(--light-100-dark);
      border-top: none;
      border-radius: 0 0 8px 0;
      margin-top: -1px;
    }
  }

  /* temp style */
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    .caseContent__left {
      margin-bottom: 2em;
      .caseContent__stickyArea {
        border-right: 1px solid var(--light-100-dark);
        border-radius: 8px;
        position: static;
        overflow: hidden;
      }
      .caseContent__dragList {
        height: 300px;
      }
    }
    .caseContent__right {
      .caseContent__stickyArea {
        border-right: 1px solid var(--light-100-dark);
        border-radius: 8px 8px 0 0;
        position: static;
        overflow: hidden;
      }
      .caseContent__caseList {
        border-radius: 0 0 8px 8px;
      }
    }
  }
`;
const EditCategoryDescription = styled.div`
  font-size: 14px;
  font-weight: normal;
`;
const Info = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
//#endregion

const layoutMeasuring = {
  strategy: LayoutMeasuringStrategy.Always,
};

const caseLiSelector = state => state.settingPageReducer.caseLi;
const errorSelector = state => state.homePageReducer.errorCenter;
const wsSelector = state => state.homePageReducer.ws;
const caseLiReselector = createSelector(caseLiSelector, errorSelector, wsSelector, (li, error, ws) => ({
  category: !isEmpty(li?.caseCategory) && isArray(li?.caseCategory) ? sortByASC([...li.caseCategory]) : [],
  loading: li.loading,
  editingCategory: li.editingCategory,
  deleting: li.deleting,
  editingItem: li.editingItem,
  currentSelectId: li.currentSelectCategoryId,
  caseItem: categoryCaseListItem(li.caseItem),
  archivedCategory: sortByDESC([...li.archivedCategory]),
  errorMsgData: error.usedList,
  duplicateError: error.duplicateName,
  serverError: error.serverError,
  clientError: error.clientError,
  hasChildrenModalVisible: error.hasChildrenModalVisible,
  usedCaseModalVisible: error.usedCaseModalVisible,
  clinicId: ws?.ws?._id,
}));

const CaseItemContent = ({
  categoryDrawerVisible,
  itemDrawerVisible,
  handleCategoryVisible,
  handleItemDrawerVisible,
  handleCategoryInvisible,
  handleItemDrawerInvisible,
  sort,
}) => {
  const { formatMessage: f } = useIntl();
  const {
    category,
    loading,
    editingCategory,
    deleting,
    editingItem,
    currentSelectId,
    caseItem,
    archivedCategory,
    errorMsgData,
    duplicateError,
    serverError,
    clientError,
    hasChildrenModalVisible,
    usedCaseModalVisible,
    clinicId,
  } = useSelector(caseLiReselector);
  const [activeId, setActiveId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [categoryFilters, setCategoryFilters] = useState(['activate', 'isArchived']);
  const [itemFilters, setItemFilters] = useState(['activate', 'isArchived']);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const activeIndex = activeId ? category.map(c => c.priority).indexOf(activeId) : -1;
  const dispatch = useDispatch();
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();
  const {
    handleSubmit: handleCategorySubmit,
    formState: { errors: categoryErrors },
    control: categoryControl,
    reset: categoryReset,
    setValue: setCategoryValue,
  } = useForm();
  const [archivedWarning, setArchivedWarning] = useState({
    visible: false,
    name: '',
    id: '',
    type: '',
  });

  const categoryBtnText = !_.isEmpty(editingCategory._id) ? 'button.save' : 'button.add';
  const categoryTitle = !_.isEmpty(editingCategory._id)
    ? f({ id: 'caseLi.editCategoryTitle' })
    : f({ id: 'caseLi.addCategoryTitle' });
  const categoryDescription = !_.isEmpty(editingCategory._id) && f({ id: 'caseLi.editCategoryDescription' });

  const itemBtnText = !_.isEmpty(editingItem._id) ? 'button.save' : 'button.add';
  const itemTitle = !_.isEmpty(editingItem._id) ? f({ id: 'caseLi.editItemTitle' }) : f({ id: 'caseLi.addItemTitle' });

  const deleteTitle = deleting.type === 'category' ? 'modal.delete.title.category' : 'modal.delete.title.caseItem';
  const deleteFailedTitle =
    deleting.type === 'category' ? 'modal.delete.fail.category.title' : 'modal.delete.fail.caseItem.title';

  useEffect(() => {
    dispatch(getCaseLi());
  }, [dispatch]);

  useEffect(() => {
    if (clientError) {
      messageError(f({ id: 'message.error' }));
      dispatch(setClientError(false));
    }
  }, [f, clientError, dispatch]);

  const handleDragStart = ({ active }) => setActiveId(active.id);

  const handleDragEnd = async ({ active, over, category }) => {
    if (active.id !== over.id) {
      const priority = category.map(c => c.priority);
      const oldIndex = priority.indexOf(active.id);
      dispatch(
        sortCaseCategory({ _id: category[oldIndex]._id, priority: over.id, oriPriority: active.id }, () =>
          messageSuccess(f({ id: 'message.success' })),
        ),
      );
    }
    setActiveId(null);
  };

  const handleDragCancel = () => setActiveId(null);

  const submitCategory = editing => {
    if (_.isEmpty(editing._id)) {
      dispatch(
        createCaseCategory({ name: editing.name.trim(), isCommonly: editing.isCommonly }, () => {
          messageSuccess(f({ id: 'message.add.success' }));
          closeCaseCategoryDrawer();
        }),
      );
    } else {
      dispatch(
        editCaseCategory({ ...editing, name: editing.name.trim() }, () => {
          messageSuccess(f({ id: 'message.success' }));
          closeCaseCategoryDrawer();
        }),
      );
    }
  };

  const submitItem = editing => {
    if (_.isEmpty(editing._id)) {
      dispatch(
        createCaseItem(
          { name: editing.name.trim(), isCommonly: editing.isCommonly, categoryId: editing.categoryId },
          () => {
            messageSuccess(f({ id: 'message.add.success' }));
            closeCaseItemDrawer();
          },
        ),
      );
    } else {
      dispatch(
        editCaseItem({ ...editing, name: editing.name.trim() }, () => {
          messageSuccess(f({ id: 'message.success' }));
          closeCaseItemDrawer();
        }),
      );
    }
  };

  const closeCaseCategoryDrawer = () => {
    handleCategoryInvisible();
    dispatch(resetCategoryForm());
    categoryReset({ name: '' });
    dispatch(setDuplicateName(false));
  };

  const closeCaseItemDrawer = () => {
    handleItemDrawerInvisible();
    dispatch(resetItemForm());
    reset({ name: '', isCommonly: false });
    dispatch(setDuplicateName(false));
  };

  const onEditCategory = item => {
    dispatch(selectCategoryItem(item));
    setCategoryValue('name', item.name);
    handleCategoryVisible();
  };

  const archiveCategory = id => {
    dispatch(
      archivedCaseCategory({ _id: id, isArchived: true }, () =>
        messageSuccess(f({ id: 'case.li.category.archived.message' })),
      ),
    );
    setArchivedWarning({ id: '', name: '', type: '', visible: false });
  };

  const activatedCategory = id => {
    dispatch(
      archivedCaseCategory({ _id: id, isArchived: false }, () =>
        messageSuccess(f({ id: 'case.li..category.activated.message' })),
      ),
    );
  };

  const onEditItem = item => {
    dispatch(selectItem(item));
    setValue('name', item.name);
    handleItemDrawerVisible();
  };

  const archiveItem = id => {
    dispatch(
      editCaseItem({ _id: id, isArchived: true, categoryId: currentSelectId, isCommonly: false }, () =>
        messageSuccess(f({ id: 'case.li.archived.message' })),
      ),
    );
    setArchivedWarning({ id: '', name: '', type: '', visible: false });
  };

  const activatedItem = id => {
    dispatch(
      editCaseItem({ _id: id, isArchived: false, categoryId: currentSelectId }, () =>
        messageSuccess(f({ id: 'case.li.activated.message' })),
      ),
    );
  };

  const switchItemCommonlyMark = (id, currentStatus) => {
    dispatch(
      editCaseItem({ _id: id, isCommonly: !currentStatus, categoryId: currentSelectId }, () =>
        messageSuccess(f({ id: 'message.success' })),
      ),
    );
  };

  const deleteListItem = (data, deleting) => {
    const successCallback = () => {
      reset({ reason: '', person: '' });
      dispatch(resetDeleteForm());
      dispatch(getCaseLi());
      messageWarning(f({ id: 'message.deleted' }));
    };
    if (deleting.type === 'category') {
      dispatch(
        deleteCaseCategory(
          { _id: deleting._id, isDeleted: true, deletedReason: data.reason, deletedOperator: data.person },
          successCallback,
          deleting.isArchived,
        ),
      );
    }
    if (deleting.type === 'item') {
      dispatch(
        deleteCaseItem(
          {
            _id: deleting._id,
            isDeleted: true,
            deletedReason: data.reason,
            deletedOperator: data.person,
            categoryId: currentSelectId,
          },
          successCallback,
        ),
      );
    }
    setIsModalVisible(false);
  };

  const showModal = ({ data, type, isArchived = false }) => {
    dispatch(getDeleteTarget({ data, type, isArchived }));
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(resetDeleteForm());
    reset({ reason: '', person: '' });
  };

  const deleteValidation = target => {
    if (target.type === 'category') {
      Validate.deleteValidation({ _id: target.data._id, target: 'instructionBookCategory' }).then(res => {
        if (!!res) {
          showModal({ ...target });
        }
      });
    }
    if (target.type === 'item') {
      Validate.deleteValidation({ _id: target.data._id, target: 'instructionBookItem' }).then(res => {
        if (!!res) {
          showModal({ ...target });
        }
      });
    }
  };

  const showWarningModal = (id, name, type) =>
    setArchivedWarning({
      id,
      name,
      visible: true,
      type,
    });

  return (
    <React.Fragment>
      <CaseLiContainer>
        <div className='caseContent__left'>
          <div className='caseContent__stickyArea'>
            <div className='caseContent__filter'>
              <div>
                <span>{f({ id: 'caseLi.category.title' })}</span>
                <TableFilter options={labSettingFilters(f)} value={categoryFilters} onChange={setCategoryFilters} />
              </div>
              <Info>
                <Tooltip title={f({ id: 'caseLi.editCategoryDescription' })}>
                  <InfoIcon />
                </Tooltip>
              </Info>
            </div>
            <div className='caseContent__dragList'>
              {loading && <Spin />}
              {_.isEmpty(category) && _.isEmpty(archivedCategory) && <Empty description={'labSetting.caseLi.empty'} />}
              {categoryFilters.length === 0 && <Empty description={'search.empty'} />}
              {categoryFilters.includes('activate') ? (
                _.isEmpty(category) ? (
                  _.isEqual(categoryFilters, ['activate']) && <Empty description={'search.empty'} />
                ) : (
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={({ active, over }) => handleDragEnd({ active, over, category })}
                    onDragCancel={handleDragCancel}
                    layoutMeasuring={layoutMeasuring}
                    modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
                  >
                    <SortableContext items={category} strategy={verticalListSortingStrategy}>
                      {category.map(data => (
                        <SortableItem
                          key={data._id}
                          id={data._id}
                          data={data}
                          activeIndex={activeIndex}
                          onEdit={onEditCategory}
                          archive={showWarningModal}
                          activate={activatedCategory}
                          onDelete={value => deleteValidation(value)}
                          isSelected={data._id === currentSelectId}
                          selectCategory={value => {
                            dispatch(selectCategory(value));
                            setInputFilter('');
                          }}
                        />
                      ))}
                    </SortableContext>
                    <DragOverlay>{activeId ? <Item id={activeId} /> : null}</DragOverlay>
                  </DndContext>
                )
              ) : null}
              {categoryFilters.includes('isArchived') && (
                <div
                  className={`dragList__archivedList${_.isEqual(categoryFilters, ['isArchived']) ? '--isEmpty' : ''}`}
                >
                  {_.isEmpty(archivedCategory) && _.isEqual(categoryFilters, ['isArchived']) && (
                    <Empty description={'search.empty'} />
                  )}
                  {archivedCategory.map(data => (
                    <div
                      key={data._id}
                      className={`dragList__archivedItem ${data._id === currentSelectId ? 'selected' : ''}`}
                      onClick={() => {
                        dispatch(selectCategory(data._id));
                        setInputFilter('');
                      }}
                    >
                      <div className={`${data?.isArchived && 'dragList__archived'}`}>
                        {data?.isArchived && (
                          <div className='dragList__archivedAlert'>{`(${f({
                            id: 'button.more.dropDownMenu.archived',
                          })})`}</div>
                        )}
                        <div>{data?.name}</div>
                      </div>
                      <MoreButton
                        name={data?.name}
                        isActivate={!data?.isArchived}
                        editHandler={() => onEditCategory(data)}
                        archiveHandler={() => showWarningModal(data?._id, data?.name, 'category')}
                        activateHandler={() => activatedCategory(data?._id)}
                        delHandler={() => deleteValidation({ data, type: 'category', isArchived: data?.isArchived })}
                        className={'caseDragList-more'}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='caseContent__right'>
          <CaseItemList
            title='caseLi.item.title'
            emptyTitle={
              !_.isEmpty(inputFilter)
                ? 'search.empty'
                : _.isEqual(itemFilters, ['activate', 'isArchived'])
                ? 'labSetting.caseLi.item.empty'
                : 'search.empty'
            }
            dataSource={
              caseItem[currentSelectId]
                ? sortBySelect({
                    source: filterBySearch(caseItem[currentSelectId], inputFilter),
                    filters: itemFilters,
                    sort,
                  })
                : []
            }
            handleEdit={onEditItem}
            handleArchived={showWarningModal}
            handleActivated={activatedItem}
            handleCommonly={switchItemCommonlyMark}
            handleDelete={data => deleteValidation({ data, type: 'item' })}
            filterValue={inputFilter}
            handleInputFilter={setInputFilter}
            filters={itemFilters}
            handleFilter={setItemFilters}
            sort={sort}
            className={'caseLiContent'}
          />
        </div>
      </CaseLiContainer>
      <DrawerComponent
        handleClose={closeCaseCategoryDrawer}
        visible={categoryDrawerVisible}
        title={
          <div>
            <div>{categoryTitle}</div>
            {categoryDescription && <EditCategoryDescription>{categoryDescription}</EditCategoryDescription>}
          </div>
        }
        footer={
          <Button
            type='primary'
            width={'150px'}
            size={'large'}
            onClick={handleCategorySubmit(() => submitCategory(editingCategory))}
            buttonText={categoryBtnText}
          />
        }
        children={
          <CaseCategoryForm
            control={categoryControl}
            error={categoryErrors}
            alertMessage={'form.alert'}
            changeName={value => {
              dispatch(editingCategoryForm({ name: value }));
              dispatch(setDuplicateName(false));
            }}
            value={editingCategory}
            validate={duplicateError}
          />
        }
      />
      <DrawerComponent
        handleClose={closeCaseItemDrawer}
        visible={itemDrawerVisible}
        title={itemTitle}
        footer={
          <Button
            type='primary'
            width={'150px'}
            size={'large'}
            onClick={handleSubmit(() => submitItem(editingItem))}
            buttonText={itemBtnText}
          />
        }
        children={
          <CaseItemCountingForm
            control={control}
            error={errors}
            alertMessage={'form.alert'}
            changeName={value => {
              dispatch(editingItemForm({ name: value }));
              dispatch(setDuplicateName(false));
            }}
            changeCommonly={e => dispatch(editingItemForm({ isCommonly: e.target.checked }))}
            value={editingItem}
            validate={duplicateError}
          />
        }
      />
      <DeleteModal
        deleteTitle={deleteTitle}
        deleteName={deleting.name}
        deleteModalVisible={isModalVisible}
        deleteData={deleting}
        handleDelete={data => deleteListItem(data, deleting)}
        handleCancel={handleCancel}
        failModalVisible={usedCaseModalVisible}
        handleModalFailVisible={() => {
          dispatch(setUsedCaseModalVisible(false));
          dispatch(resetUsedList());
        }}
        deleteFailedTitle={deleteFailedTitle}
        deleteFailedDescription={'modal.delete.fail.description'}
        usedList={errorMsgData}
        hasItemModalVisible={hasChildrenModalVisible}
        handleHasCaseItemModalVisible={() => dispatch(setHasChildrenModalVisible(false))}
        serverError={serverError}
        clinicId={clinicId}
      />
      <CustomizeModal
        title={
          <EllipsisText>
            <EllipsisText.Normal>
              {f({
                id: archivedWarning.type === 'category' ? 'labSetting.archived.category' : 'labSetting.archived.item',
              })}
            </EllipsisText.Normal>
            <EllipsisText.Ellipsis>{archivedWarning.name}</EllipsisText.Ellipsis>
            <EllipsisText.Normal>?</EllipsisText.Normal>
          </EllipsisText>
        }
        type={'warning'}
        visible={archivedWarning.visible}
        content={f({
          id: archivedWarning.type === 'category' ? 'lab.modal.warning.category' : 'lab.modal.warning.content',
        })}
        showCloseIcon={false}
        className={'wide'}
        handleOk={() =>
          archivedWarning.type === 'category' ? archiveCategory(archivedWarning.id) : archiveItem(archivedWarning.id)
        }
        handleCancel={() => setArchivedWarning({ id: '', name: '', type: '', visible: false })}
        footer={
          <div>
            <Button
              buttonText='labSetting.table.filterByArchived'
              type={'warning'}
              width={'82px'}
              size={'large'}
              onClick={() =>
                archivedWarning.type === 'category'
                  ? archiveCategory(archivedWarning.id)
                  : archiveItem(archivedWarning.id)
              }
            />
            <Button
              buttonText='button.cancel'
              type={'ghost-basic'}
              width={'82px'}
              size={'large'}
              onClick={() => setArchivedWarning({ id: '', name: '', type: '', visible: false })}
            />
          </div>
        }
      />
    </React.Fragment>
  );
};

export default CaseItemContent;
