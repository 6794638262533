import _ from 'lodash';
import progressStatus from '../../../../../constants/progressStatus';

const { CREATED } = progressStatus;
export const mapCaseOptionsToFilter = options => {
  const filter = [];
  for (const [option, value] of Object.entries(options)) {
    switch (option) {
      case 'skipArchivedData':
        if (value) filter.push({ property: 'isArchived', type: 'Boolean', value: 2 });
        break;
      case 'laboratory':
        if (!_.isEmpty(value) && value !== 'all')
          filter.push({ property: 'laboratoryId', type: 'String', value: value });
        break;
      case 'doctor':
        if (!_.isEmpty(value) && value !== 'all') {
          const parsed = typeof value === 'string' ? parseInt(value, 10) : value;
          filter.push({ property: 'doctorData.id', type: 'Number', value: parsed });
        }
        break;
      case 'treatmentCategory':
        if (!_.isEmpty(value) && value !== 'all')
          filter.push({ property: 'treatmentCategoryId', type: 'String', value: value });
        break;
      case 'labOrder':
        if (!_.isEmpty(value) && value !== 'all')
          filter.push({ property: 'name', type: 'String', value: value, isExact: false });
        break;
      case 'patient':
        if (!_.isEmpty(value) && value !== 'all')
          filter.push({
            value: value,
            isExact: false,
            or_columns: [
              { property: 'patientData.name', type: 'String' },
              { property: 'patientData.birth', type: 'String' },
            ],
          });
        break;
      case 'datePicked':
        const {
          progress,
          dateRange: [dateFrom, dateTo],
        } = value;

        if (!progress || !dateFrom || !dateTo) break;
        if (progress === 'estimatedReturnDate') {
          filter.push({
            property: 'estimatedReturnDate',
            type: 'DateRange',
            value: { from: dateFrom, to: dateTo },
          });
          break;
        }
        if (progress === 'createdDate') {
          filter.push({
            property: 'createdDate',
            type: 'DateRange',
            value: { from: dateFrom, to: dateTo },
          });
          break;
        }
        filter.push({
          property: 'progressPackage.completedDate',
          type: 'DateRange',
          target_progress: [progress],
          value: { from: dateFrom, to: dateTo },
        });
        break;
      case 'progress':
        const { _id, name, order } = value;
        if (_id === 'created') {
          filter.push({ property: 'progressPackage.status', type: 'String', value: CREATED });
          break;
        }
        if (!_.isEmpty(value) && _id !== 'all') {
          filter.push({
            type: 'String',
            property: 'progressPackage',
            target_order: Number(order),
            value: [_id, name],
          });
        }
        break;
      default:
        break;
    }
  }
  return filter;
};
