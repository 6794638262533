import { instance } from '@/utils/request.utility';

export default class CaseLi {
  static getCaseCategory = async () => {
    try {
      const { data } = await instance({
        url: `/lab/instruction-books/category`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static createCaseCategory = async body => {
    try {
      const { data } = await instance({
        url: `/lab/instruction-books/category`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editCaseCategory = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/instruction-books/category/${_id}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static sortCaseCategory = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/instruction-books/category/${_id}/order`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static getCaseItem = async () => {
    try {
      const { data } = await instance({
        url: `/lab/instruction-books/items`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static createCaseItem = async body => {
    try {
      const { data } = await instance({
        url: `/lab/instruction-books/items`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editCaseItem = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/instruction-books/items/${_id}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
