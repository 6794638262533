import { instance } from '@/utils/request.utility';

export default class LabSetting {
  static getLabSetting = async () => {
    try {
      const { data } = await instance({
        url: `/lab/laboratories`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static createLab = async body => {
    try {
      const { data } = await instance({
        url: `/lab/laboratories`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editLab = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/laboratories/${_id}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
