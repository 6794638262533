import { instance } from '@/utils/request.utility';

export default class CaseType {
  static getCaseType = async () => {
    try {
      const { data } = await instance({
        url: `/lab/treatments/category`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static createCaseType = async body => {
    try {
      const { data } = await instance({
        url: `/lab/treatments/category`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editCaseType = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/treatments/category/${_id}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
