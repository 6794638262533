import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useForm } from 'react-hook-form';
import CaseItemList from './CaseItemList';
import DrawerComponent from './Drawer';
import { Button } from '../../../component/Button';
import CaseItemForm from './CaseItemForm';
import Empty from '../../../component/Empty';
import {
  editingCaseType,
  resetForm,
  selectCaseTypeItem,
  resetDeleteForm,
  getDeleteTarget,
} from '../slice/caseTypeSlice';
import { messageSuccess, messageError, messageWarning } from '@/component/Message';
import DeleteModal from './DeleteModal';
import { filterBySearch } from '../utils/filterBySearch';
import { sortBySelect } from '../utils/sortBySelect';
import { getCaseTypeData, editCaseType, createCaseType, deleteCaseType } from '../utils/handleCaseTypeData';
import CustomizeModal from '../../../component/Modal';
import {
  resetUsedList,
  setClientError,
  setDuplicateName,
  setUsedCaseModalVisible,
} from '../../HomePage/slice/errorCenterSlice';
import { EllipsisText } from '../../../component/Display';
import { EmptyContainer } from './CasePayContent';
import Validate from '../../../models/validate';
import Spin from '../../../component/Spin';

const errorSelector = state => state.homePageReducer.errorCenter;
const caseTypeSelector = state => state.settingPageReducer.caseType;
const wsSelector = state => state.homePageReducer.ws;
const caseTypeReselector = createSelector(caseTypeSelector, errorSelector, wsSelector, (type, error, ws) => ({
  loading: type.loading,
  type: type.caseType,
  editing: type.editing,
  deleting: type.deleting,
  errorMsgData: error.usedList,
  duplicateError: error.duplicateName,
  serverError: error.serverError,
  clientError: error.clientError,
  usedCaseModalVisible: error.usedCaseModalVisible,
  clinicId: ws?.ws?._id,
}));

const CaseTypeContent = ({ drawerVisible, handleDrawerClose, handleVisible, sort }) => {
  const { formatMessage: f } = useIntl();
  const dispatch = useDispatch();
  const {
    loading,
    type,
    editing,
    deleting,
    errorMsgData,
    duplicateError,
    serverError,
    clientError,
    usedCaseModalVisible,
    clinicId,
  } = useSelector(caseTypeReselector);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputFilter, setInputFilter] = useState('');
  const [filters, setFilters] = useState(['activate', 'isArchived']);
  const [archivedWarning, setArchivedWarning] = useState({
    visible: false,
    name: '',
    id: '',
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue,
  } = useForm();

  const btnText = !_.isEmpty(editing._id) ? 'button.save' : 'button.add';
  const title = !_.isEmpty(editing._id)
    ? f({ id: 'caseType.editCaseTypeTitle' })
    : f({ id: 'caseType.addCaseTypeTitle' });

  useEffect(() => {
    dispatch(getCaseTypeData());
  }, [dispatch]);

  useEffect(() => {
    if (clientError) {
      dispatch(setClientError(false));
      messageError(f({ id: 'message.error' }));
    }
  }, [f, clientError, dispatch]);

  const handleClose = () => {
    handleDrawerClose();
    reset({ name: '' });
    dispatch(resetForm());
    dispatch(setDuplicateName(false));
  };

  const submitHandler = async editing => {
    if (_.isEmpty(editing._id)) {
      await createCaseType({ name: editing.name.trim(), isCommonly: editing.isCommonly }, () => {
        messageSuccess(f({ id: 'message.add.success' }));
        handleClose();
        dispatch(getCaseTypeData());
      });
    } else {
      dispatch(
        editCaseType({ ...editing, name: editing.name.trim() }, () => {
          messageSuccess(f({ id: 'message.success' }));
          handleClose();
        }),
      );
    }
  };

  const onEditTypeItem = item => {
    dispatch(selectCaseTypeItem(item));
    setValue('name', item.name);
    handleVisible();
  };

  const archiveTypeItem = id => {
    dispatch(
      editCaseType({ _id: id, isArchived: true, isCommonly: false }, () =>
        messageSuccess(f({ id: 'case.type.archived.message' })),
      ),
    );
    setArchivedWarning({ id: '', name: '', visible: false });
  };

  const activatedTypeItem = id => {
    dispatch(
      editCaseType({ _id: id, isArchived: false }, () => messageSuccess(f({ id: 'case.type.activate.message' }))),
    );
  };

  const switchCommonlyMark = (id, currentStatus) => {
    dispatch(editCaseType({ _id: id, isCommonly: !currentStatus }, () => messageSuccess(f({ id: 'message.success' }))));
  };

  const deleteTypeItem = (data, deleting) => {
    const successCallback = () => {
      reset({ reason: '', person: '' });
      dispatch(resetDeleteForm());
      dispatch(getCaseTypeData());
      messageWarning(f({ id: 'message.deleted' }));
    };
    dispatch(
      deleteCaseType(
        { _id: deleting._id, isDeleted: true, deletedReason: data.reason, deletedOperator: data.person },
        successCallback,
      ),
    );
    setIsModalVisible(false);
  };

  const showModal = data => {
    dispatch(getDeleteTarget(data));
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    dispatch(resetDeleteForm());
    reset({ reason: '', person: '' });
  };

  const showWarningModal = (id, name) =>
    setArchivedWarning({
      id,
      name,
      visible: true,
    });

  const deleteValidation = target => {
    Validate.deleteValidation({ _id: target._id, target: 'treatmentCategory' }).then(res => {
      if (!!res) {
        showModal(target);
      }
    });
  };

  if (loading) {
    return <Spin />;
  }
  if (_.isEmpty(type)) {
    return (
      <EmptyContainer>
        <Empty description={'labSetting.caseType.empty'} />
        <DrawerComponent
          handleClose={handleClose}
          visible={drawerVisible}
          title={f({ id: 'caseType.addCaseTypeTitle' })}
          footer={
            <Button
              type='primary'
              width={'150px'}
              size={'large'}
              onClick={handleSubmit(() => submitHandler(editing))}
              buttonText={'button.add'}
            />
          }
          children={
            <CaseItemForm
              control={control}
              error={errors}
              changeName={value => {
                dispatch(editingCaseType({ name: value }));
                dispatch(setDuplicateName(false));
              }}
              changeCommonly={e => dispatch(editingCaseType({ isCommonly: e.target.checked }))}
              value={editing}
              validate={duplicateError}
            />
          }
        />
      </EmptyContainer>
    );
  }

  return (
    <div>
      <CaseItemList
        title='caseType.filter.title'
        dataSource={sortBySelect({ source: filterBySearch(type, inputFilter), filters, sort })}
        handleEdit={onEditTypeItem}
        handleArchived={showWarningModal}
        handleActivated={activatedTypeItem}
        handleCommonly={switchCommonlyMark}
        handleDelete={deleteValidation}
        filterValue={inputFilter}
        handleInputFilter={setInputFilter}
        filters={filters}
        handleFilter={setFilters}
        sort={sort}
        emptyTitle={'search.empty'}
      />
      <DrawerComponent
        handleClose={handleClose}
        visible={drawerVisible}
        title={title}
        footer={
          <Button
            type='primary'
            width={'150px'}
            size={'large'}
            onClick={handleSubmit(() => submitHandler(editing))}
            buttonText={btnText}
          />
        }
        children={
          <CaseItemForm
            control={control}
            error={errors}
            changeName={value => {
              dispatch(editingCaseType({ name: value }));
              dispatch(setDuplicateName(false));
            }}
            changeCommonly={e => dispatch(editingCaseType({ isCommonly: e.target.checked }))}
            value={editing}
            validate={duplicateError}
          />
        }
      />
      <DeleteModal
        deleteTitle={'modal.delete.title.caseType'}
        deleteName={deleting.name}
        deleteModalVisible={isModalVisible}
        deleteData={deleting}
        handleDelete={data => deleteTypeItem(data, deleting)}
        handleCancel={handleCancel}
        failModalVisible={usedCaseModalVisible}
        handleModalFailVisible={() => {
          dispatch(setUsedCaseModalVisible(false));
          dispatch(resetUsedList());
        }}
        deleteFailedTitle={'modal.delete.fail.caseType.title'}
        deleteFailedDescription={'modal.delete.fail.description'}
        usedList={errorMsgData}
        serverError={serverError}
        clinicId={clinicId}
      />
      <CustomizeModal
        title={
          <EllipsisText>
            <EllipsisText.Normal>{f({ id: 'labSetting.archived.caseType' })}</EllipsisText.Normal>
            <EllipsisText.Ellipsis>{archivedWarning.name}</EllipsisText.Ellipsis>
            <EllipsisText.Normal>?</EllipsisText.Normal>
          </EllipsisText>
        }
        type={'warning'}
        visible={archivedWarning.visible}
        content={f({ id: 'lab.modal.warning.content' })}
        showCloseIcon={false}
        className={'wide'}
        handleCancel={() => setArchivedWarning({ id: '', name: '', visible: false })}
        handleOk={() => archiveTypeItem(archivedWarning.id)}
        footer={
          <div>
            <Button
              buttonText='labSetting.table.filterByArchived'
              type={'warning'}
              width={'82px'}
              size={'large'}
              onClick={() => archiveTypeItem(archivedWarning.id)}
            />
            <Button
              buttonText='button.cancel'
              type={'ghost-basic'}
              width={'82px'}
              size={'large'}
              onClick={() => setArchivedWarning({ id: '', name: '', visible: false })}
            />
          </div>
        }
      />
    </div>
  );
};

export default CaseTypeContent;
