import { instance } from '@/utils/request.utility';

export default class Teeth {
  static getTeeth = async () => {
    try {
      const { data } = await instance({
        url: `/lab/teeth`,
        method: 'GET',
      })
      return data || [];
    } catch (error) {
      return console.error(error);
    }
  }
}