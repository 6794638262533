import { casUrl } from '@/config';
import { io } from 'socket.io-client';
import { getSToken } from '@/utils/shareToken';

const URL = casUrl || undefined;

export const socket = io(URL, {
  transports: ['websocket'],
  withCredentials: true,
});
socket.on('connect', () => {
  console.log('Socket connected:', socket.id);
});

socket.on('disconnect', () => {
  console.log('Socket disconnected');
});

export const selfSocket = io(URL, {
  transports: ['websocket'],
  withCredentials: true,
  path: '/lab/ws',
  auth: cb => {
    cb({
      token: getSToken(),
    });
  },
})
selfSocket.on('connect', () => {
  console.log('selfSocket connected:', selfSocket.id);
});

selfSocket.on('disconnect', () => {
  console.log('selfSocket disconnected');
});