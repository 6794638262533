import { createSlice } from '@reduxjs/toolkit';
import { getCaseSuccess, resetCase, resetEdited } from './caseSlice';
import Teeth from '@/models/teeth';

const teethSlice = createSlice({
  name: 'CasePage/teeth',
  initialState: {
    teethList: [],
    selectedTeeth: [],
    loading: false,
    alert: false,
    edited: false,
  },
  reducers: {
    getTeethList: (state, action) => {
      state.teethList = action.payload;
    },
    addSelectedTeeth: (state, action) => {
      state.edited = true;
      state.alert = false;
      state.selectedTeeth = [
        ...state.selectedTeeth,
        {
          value: action.payload.value,
        },
      ];
    },
    setToothMissing: (state, action) => {
      state.selectedTeeth = state.selectedTeeth.map(tooth => {
        if (tooth.value === action.payload.value) {
          return {
            ...tooth,
            isMissing: true,
          };
        }
        return tooth;
      });
    },
    deleteSelectedTooth: (state, action) => {
      state.selectedTeeth = state.selectedTeeth.filter(tooth => tooth.value !== action.payload.value);
    },
    setLoadingStart: state => {
      state.loading = true;
    },
    setLoadingEnd: state => {
      state.loading = false;
    },
    validateEmpty: (state, action) => {
      state.alert = action.payload.teeth.length === 0;
    },
    resetValidateEmpty: (state, action) => {
      state.alert = action.payload;
    },
  },
  extraReducers: {
    [getCaseSuccess](state, action) {
      state.selectedTeeth = action.payload.teeth;
      state.alert = false;
      state.edited = false;
    },
    [resetCase](state) {
      state.selectedTeeth = [];
      state.edited = false;
    },
    [resetEdited](state) {
      state.edited = false;
    },
  },
});

export const {
  getTeethList,
  addSelectedTeeth,
  setToothMissing,
  deleteSelectedTooth,
  setLoadingStart,
  setLoadingEnd,
  validateEmpty,
  resetValidateEmpty,
} = teethSlice.actions;

export const getTeethListAPI = () => dispatch => {
  dispatch(setLoadingStart());
  Teeth.getTeeth().then(res => {
    dispatch(getTeethList(res));
    dispatch(setLoadingEnd());
  });
};

export default teethSlice.reducer;
