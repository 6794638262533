import { instance } from '@/utils/request.utility';

export default class Progress {
  static getProgress = async () => {
    const params = {
      sort: JSON.stringify({ priority: 1 }),
    }
    const query = new URLSearchParams(params).toString();
    try {
      const { data } = await instance({
        url: `/lab/progress-items?${query}`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }
}
