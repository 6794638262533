import { selfSocket, socket } from '@/socket';
import store from '@/store';
import { addToComment, addToCommentGroupList, updateGroupData } from '@/container/SharedPage/slice/commentSlice';
import { updateLabCase } from '@/container/SharedPage/slice/caseSlice';
import { updateSharedCase } from '@/container/CasePage/slice/shareSlice';
import { setPasswordChanged } from '@/container/SharedPage/slice/authSlice';
import { removeAuthTokens, removeWorkspaceId } from '@/utils/token';
import { useEffect } from 'react';

const useSocket = (currentUser) => {
  useEffect(() => {
    function onUserSignOut({ userId }) {
      if (currentUser && userId && currentUser._id === userId) {
        removeAuthTokens();
        removeWorkspaceId();
        window.location.reload();
      }
    }
    socket.on('userSignOut', onUserSignOut);
    return () => {
      socket.off('userSignOut', onUserSignOut);
    };
  }, [currentUser]);

  return {
    emitSignOut: roomId => {
      socket.emit('leaveRoom', { roomId });
    },
  };
}

const useSelfSocket = () => {
  useEffect(() => {
    function onBroadcastNewCommentGroup({ comment, group }) {
      store.dispatch(addToCommentGroupList(group));
      store.dispatch(addToComment(comment));
    }
    selfSocket.on('broadcast-new-comment-group', onBroadcastNewCommentGroup);
    return () => {
      selfSocket.off('broadcast-new-comment-group', onBroadcastNewCommentGroup);
    };
  }, []);
  useEffect(() => {
    function onBroadcastUpdateCommentGroup({ group }) {
      store.dispatch(updateGroupData(group));
    }
    selfSocket.on('broadcast-update-comment-group', onBroadcastUpdateCommentGroup);
    return () => {
      selfSocket.off('broadcast-update-comment-group', onBroadcastUpdateCommentGroup);
    };
  }, []);
  useEffect(() => {
    function onBroadcastNewComment({ comment }) {
      store.dispatch(addToComment(comment));
    }
    selfSocket.on('broadcast-new-comment', onBroadcastNewComment);
    return () => {
      selfSocket.off('broadcast-new-comment', onBroadcastNewComment);
    };
  }, []);

  useEffect(() => {
    function onCaseHasUpdated({ order }) {
      console.log('case-has-updated', order);
      store.dispatch(updateLabCase(order));
    }
    selfSocket.on('case-has-updated', onCaseHasUpdated);
    return () => {
      selfSocket.off('case-has-updated', onCaseHasUpdated);
    };
  }, []);
  useEffect(() => {
    function onSharedCaseUpdated({ shareCase }) {
      store.dispatch(updateSharedCase(shareCase));
    }
    selfSocket.on('shared-case-has-updated', onSharedCaseUpdated);
    return () => {
      selfSocket.off('shared-case-has-updated', onSharedCaseUpdated);
    };
  }, []);
  useEffect(() => {
    function onSharedPasswordChanged() {
      store.dispatch(setPasswordChanged());
    }
    selfSocket.on('share:password-changed', onSharedPasswordChanged);
    return () => {
      selfSocket.off('share:password-changed', onSharedPasswordChanged);
    };
  }, []);
};

export {
  useSocket,
  useSelfSocket
}