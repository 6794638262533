import { instance } from '@/utils/request.utility';

export default class Doctors {
  static getDoctors = async (page = 0, size = 1000) => {
    try {
      const { data } = await instance({
        url: `/lab/his/doctors?page=${page}&size=${size}`,
        method: 'GET',
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
