import axios from 'axios';

import { getAccessToken, getWorkspaceId, removeAuthTokens, saveAccessToken } from './token';
import { cas_api_url } from '@/config';
import store from '@/store';
import { getUsedList, setClientError, setDuplicateName, setHasChildrenModalVisible, setPasswordError, setUsedCaseModalVisible } from '@/container/HomePage/slice/errorCenterSlice';

const instance = axios.create({
  baseURL: cas_api_url,
  withCredentials: true,
});

instance.interceptors.request.use(
  config => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    const workspaceId = getWorkspaceId();
    if (workspaceId) {
      config.headers.workspace_id = workspaceId;
    }
    return config;
  },
  error => Promise.reject(error),
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  async error => {
    if (error.response) {
      const data = error.response.data.error;

      switch (data.code) {
        case 'ACCESS_TOKEN_IS_EXPIRED':
          await removeAuthTokens();
          try {
            return instance({
              url: `/auth/access-token:renew`,
              method: 'POST',
            }).then(response => {
              const newAccessToken = response.data;
              saveAccessToken(newAccessToken);
              error.config.headers.Authorization = `Bearer ${newAccessToken}`;
              return instance.request(error.config);
            });
          } catch (renewError) {
            return Promise.reject(renewError);
          }
        case 'DUPLICATED_NAME': {
          store.dispatch(setDuplicateName(true));
          break;
        }
        case 'DELETE_NOT_CLEAN_IN_LAB_ORDER':
        case 'TARGET_NOT_FOUND':{
          store.dispatch(getUsedList(error.response.data.error?.data || []));
          store.dispatch(setUsedCaseModalVisible(true));
          break;
        }
        case 'DELETE_NOT_CLEAN_IN_INSTRUCTION_BOOK_CATEGORY': {
          store.dispatch(setHasChildrenModalVisible(true));
          break;
        }
        case 'INVALID_DELETE_TARGET': {
          store.dispatch(setPasswordError(true));
          break;
        }
        case 'LICENSE_STATE_NOT_GRANT': {
          return (window.location.href = window.location.href + '/invalid');
        }
        case 'INTERNAL_SERVER_ERROR':
        default:
          store.dispatch(setClientError(true));
          return Promise.reject(data);
      }
    } else {
      return Promise.reject(error);
    }
  },
);

export { instance };
