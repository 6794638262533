import { instance } from '@/utils/request.utility';

export default class CasePay {
  static getCasePay = async () => {
    try {
      const { data } = await instance({
        url: `/lab/payment-methods`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static createCasePay = async body => {
    try {
      const { data } = await instance({
        url: `/lab/payment-methods`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editCasePay = async body => {
    try {
      const _id = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/payment-methods/${_id}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
