import { mapCaseOptionsToFilter } from '@/container/HomePage/component/DashboardTableSearch/utils/mapCaseOptionsToFilter';
import { instance } from '@/utils/request.utility';

export default class Case {
  static getCaseByCaseName = async name => {
    try {
      const { data } = await instance({
        url: `/lab/orders/by-name/${name}`,
        method: 'GET',
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static getCaseByPatientId = async patientId => {
    const filters = JSON.stringify([{
      property: 'patientData.id',
      value: Number(patientId),
      is_exact: true,
      or_columns: [],
      type: 'Number',
    }]);
    const queryString = new URLSearchParams({ filters }).toString();
    try {
      const { data } = await instance({
        url: `/lab/orders?${queryString}`,
        method: 'GET',
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static createCase = async body => {
    try {
      const { data } = await instance({
        url: `/lab/orders`,
        method: 'POST',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static editCase = async body => {
    try {
      const orderId = body._id;
      delete body._id;
      const { data } = await instance({
        url: `/lab/orders/${orderId}`,
        method: 'PUT',
        data: body,
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }

  static getCaseByOptions = async (data) => {
    const { progress } = data?.datePicked;
    const sort = [
      {
        property: 'progressPackage.completedDate',
        direction: 'DESC',
      },
    ];
    if (progress === 'estimatedReturnDate') sort[0].property = 'estimatedReturnDate';
    if (progress === 'createdDate') sort[0].property = 'createdDate';
    const filter = mapCaseOptionsToFilter(data);
    const params = {
      filters: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    }

    const query = new URLSearchParams(params).toString();
    try {
      const { data } = await instance({
        url: `/lab/orders?${query}`,
        method: 'GET',
      })
      return data?.records || [];
    } catch (error) {
      return console.error(error);
    }
  }

  static getCaseAmount = async data => {
    const filter = mapCaseOptionsToFilter(data);
    const params = {
      filters: JSON.stringify(filter)
    }
    const query = new URLSearchParams(params).toString();
    try {
      const { data } = await instance({
        url: `/lab/orders?${query}`,
        method: 'GET',
      })
      return data?.count || 0;
    } catch (error) {
      return console.error(error);
    }
  };
}
