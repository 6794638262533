import { instance } from '@/utils/request.utility';

export default class Validate {
  static deleteValidation = async ({ _id, target }) => {
    try {
      const { data } = await instance({
        url: `/lab/validation/settings/deleted?_id=${_id}&target=${target}`,
        method: 'GET',
      })
      return data;
    } catch (error) {
      return console.error(error);
    }
  }
}
